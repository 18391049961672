/*
 *
 */

import React from 'react';
import Section from 'components/section';
import Text from 'components/text';
import Hero from 'components/hero';
import { useIntl } from 'react-intl';
import Helmet from 'react-helmet';


/*
 *
 */

export default function QRCodeMenu () {

	const intl = useIntl();

	return (
		<React.Fragment>
			<Helmet>
				<title>{intl.formatMessage({ id: 'page.qr-code-menu.meta_title' })}</title>
				<meta name="description" content={intl.formatMessage({ id: 'page.qr-code-menu.meta_desc' })} />
				<meta property="og:image" content={`http://www.aide-aux-restaurateurs.fr/img/share_fr_online-menu.jpg`} />
				<meta property="og:url" content={`http://www.aide-aux-restaurateurs.fr/qr-code-menu`} />
			</Helmet>
			<Section>
				<Hero
					title={intl.formatMessage({ id: 'page.qr-code-menu.hero.title' })}
					subtitle={intl.formatMessage({ id: 'page.qr-code-menu.hero.subtitle' })}
				/>
			</Section>
		<Section>
			<iframe 
				title="Demande de Menu QR Code"
				src="https://docs.google.com/forms/d/e/1FAIpQLSednZr9xLWYdgLsFgyXiHxlP7gHCcwo4g1bFiU8yUdZAt8tFg/viewform?embedded=true" 
				width="100%" 
				height="2582" 
				frameborder="0" 
				marginheight="0" 
				marginwidth="0">Loading…</iframe>
		</Section>
		<Section>
			<Text>
				<p>La crise COVID-19 rend nécessaire la mise en place de menus sans contact. Grace à la solution offerte par CentarlApp Nous créons une page en ligne qui inclut vos menus ainsi qu'un QR Code unique à votre restaurant</p>
			</Text>
		</Section>
		</React.Fragment>
	);
}